<template>
  <div id="settings-vehicle">
    <b-card footer-tag="footer">

      <rc-overlay :show="loading">
        <b-card-text>
          <validation-observer ref="editVehicleValidation">
            <form
              id="editVehicleForm"
              @submit.prevent
            >

              <b-tabs>
                <b-tab :title="$t('conf.vehicle.tabs.base-info')">

                  <b-row>
                    <b-col
                      cols="12"
                      class="pb-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.vehicle.brand')"
                        rules="required|min:3|max:50"
                      >
                        <label for="brand">
                          {{ $t('conf.vehicle.brand') }}
                        </label>
                        <b-form-input
                          id="brand"
                          v-model="vehicle.brand"
                          type="text"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('conf.vehicle.brand')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.vehicle.model')"
                        rules="required|min:3|max:50"
                      >
                        <label for="model">
                          {{ $t('conf.vehicle.model') }}
                        </label>
                        <b-form-input
                          id="model"
                          v-model="vehicle.model"
                          type="text"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('conf.vehicle.model')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.vehicle.registration-number')"
                        rules="required|alpha-num|min:3|max:10"
                      >
                        <label for="registrationNumber">
                          {{ $t('conf.vehicle.registration-number') }}
                        </label>
                        <b-form-input
                          id="registrationNumber"
                          v-model="vehicle.registrationNumber"
                          type="text"
                          minlength="3"
                          maxlength="10"
                          :placeholder="$t('conf.vehicle.registration-number')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.vehicle.side-number')"
                        rules="required|alpha-num|min:3|max:10"
                      >
                        <label for="sideNumber">
                          {{ $t('conf.vehicle.side-number') }}
                        </label>
                        <b-form-input
                          id="sideNumber"
                          v-model="vehicle.sideNumber"
                          type="text"
                          minlength="3"
                          maxlength="10"
                          :placeholder="$t('conf.vehicle.side-number')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.vehicle.gps-identifier')"
                        rules=""
                      >
                        <b-form-group>
                          <label for="vehicleTrackingIdentifier">
                            {{ $t('conf.vehicle.gps-identifier') }}
                          </label>
                          <v-select
                            id="vehicleTrackingIdentifier"
                            v-model="vehicle.gpsIdentifier"
                            :placeholder="$t('conf.vehicle.gps-identifier')"
                            :options="trackingIdentifiers"
                            :reduce="gpsIdentifier => gpsIdentifier.externalIdentifier"
                          >
                            <span
                              slot="selected-option"
                              slot-scope="option"
                            >
                              {{ option.description }} ({{ option.externalIdentifier }})
                            </span>
                            <template #option="{ externalIdentifier, description}">
                              <span>{{ description }} ({{ externalIdentifier }})</span>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>

                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.vehicle.status')"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="vehicleStatus">
                            {{ $t('conf.vehicle.status') }}
                          </label>
                          <v-select
                            id="vehicleStatus"
                            v-model="vehicle.status"
                            :placeholder="$t('conf.vehicle.status')"
                            :options="statuses"
                            :clearable="false"
                            :searchable="false"
                          >
                            <span
                              slot="selected-option"
                              slot-scope="option"
                            >
                              {{ $t('conf.vehicle.statuses.' + option.label) }}
                            </span>
                            <template #option="{ label }">
                              <span>{{ $t('conf.vehicle.statuses.' + label) }}</span>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>

                      </validation-provider>

                    </b-col>

                  </b-row>
                </b-tab>

                <b-tab :title="$t('conf.vehicle.tabs.checklist')">

                  <b-row>
                    <b-col
                      cols="12"
                      class="pb-50"
                    >
                      <checklist-item-autosuggest
                        class="mb-2"
                        @item:selected="addChecklistItem"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      class="pb-50"
                    >

                      <draggable
                        v-model="checklistItems"
                        tag="ul"
                        group="checklistItems"
                        class="list-group list-group-flush"
                        handle=".handle"
                      >
                        <b-list-group-item
                          v-for="(checklistItem, index) in checklistItems"
                          :key="checklistItem.id"
                          tag="li"
                        >
                          <div class="d-flex justify-content-between">
                            <div class="d-inline-flex">
                              <feather-icon
                                icon="MenuIcon"
                                class="mr-1 handle cursor-move font-medium-5"
                              />
                              <b-avatar
                                v-if="checklistItem.checklistDictionary.active"
                                variant="light-success"
                                size="24"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                />
                              </b-avatar>
                              <b-avatar
                                v-else
                                variant="light-danger"
                                size="24"
                              >
                                <feather-icon
                                  icon="XIcon"
                                />
                              </b-avatar>
                              <div class="ml-1">
                                <b-card-text class="mb-0 mt-25 font-weight-bold">
                                  {{ checklistItem.checklistDictionary.value }}
                                </b-card-text>
                              </div>
                            </div>
                            <b-button
                              variant="flat-danger"
                              class="btn-sm"
                              @click="removeChecklistItem(index)"
                            >
                              <feather-icon icon="Trash2Icon" />
                            </b-button>
                          </div>

                        </b-list-group-item>
                      </draggable>
                    </b-col>
                  </b-row>

                </b-tab>

                <b-tab
                  :title="$t('conf.vehicle.tabs.financial-info')"
                  :disabled="!hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])"
                >
                  <b-row v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])">
                    <b-col
                      cols="12"
                      class="pb-50"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.vehicle.financial.fuel-cost')"
                        rules=""
                      >
                        <label for="fuelCostPerOneKm">
                          {{ $t('conf.vehicle.financial.fuel-cost') }}
                        </label>
                        <b-input-group>
                          <b-form-input
                            id="fuelCostPerOneKm"
                            v-model="vehicle.fuelCostPerOneKm"
                            type="number"
                            step="0.01"
                            :placeholder="$t('conf.vehicle.financial.fuel-cost')"
                            autocomplete="off"
                          />
                          <b-input-group-append is-text>
                            {{ $t('shared.currency-symbol') }} / 1 km
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <!--
                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.vehicle.financial.additional-costs')"
                        rules=""
                      >
                        <label for="additionalCosts">
                          {{ $t('conf.vehicle.financial.additional-costs') }}
                        </label>
                        <b-input-group>
                          <b-form-input
                            id="additionalCosts"
                            v-model="vehicle.additionalCostsDaily"
                            type="number"
                            step="0.01"
                            :placeholder="$t('conf.vehicle.financial.additional-costs')"
                            autocomplete="off"
                          />
                          <b-input-group-append is-text>
                            / {{ $t('shared.time-d') }}
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
-->
                  </b-row>

                </b-tab>
              </b-tabs>
            </form>
          </validation-observer>
        </b-card-text>
      </rc-overlay>

      <template #footer>

        <div class="text-right">
          <b-button
            class="mr-1"
            variant="outline-warning"
            @click="$router.back()"
          >
            {{ $t('shared.button.cancel') }}
          </b-button>

          <b-button
            variant="primary"
            :disabled="loading"
            @click="submitForm"
          >
            {{ $t('shared.button.submit') }}
          </b-button>
        </div>

      </template>

    </b-card>
  </div>
</template>

<script>
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import draggable from 'vuedraggable'
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BListGroupItem,
  BRow,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import guard from '@/guard'
import ChecklistItemAutosuggest from '@/views/vehicles/VehicleEdit/ChecklistItemAutosuggest.vue'

export default {
  components: {
    ChecklistItemAutosuggest,
    BFormInput,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BTab,
    BTabs,
    BInputGroup,
    BInputGroupAppend,
    BListGroupItem,
    BAvatar,
    draggable,

    RcOverlay,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      trackingIdentifiers: [],
      loading: false,
    }
  },
  computed: {
    vehicle: {
      get() {
        return cloneNested(this.$store.getters['vehicle/getVehicleData'])
      },
      set(vehicle) {
        this.$store.commit('vehicle/SET_VEHICLE_EDITED', vehicle)
      },
    },
    checklistItems: {
      get() {
        return this.vehicle.checklistItems
      },
      set(checklistItems) {
        const vehicle = cloneNested(this.vehicle)
        vehicle.checklistItems = checklistItems
        this.$store.commit('vehicle/SET_VEHICLE_EDITED', vehicle)
      },
    },
    statuses() {
      return cloneNested(this.$store.getters['vehicle/getVehicleStatuses'])
    },
    me() {
      return this.$store.state.auth.activeUser
    },
  },
  created() {
    this.initVehicleData()
  },
  beforeDestroy() {
    this.$store.commit('vehicle/CLEAR_VEHICLE_DATA')
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    initVehicleData() {
      this.loading = true
      this.$store.dispatch('vehicle/fetchVehicleById', this.$route.params.id)
        .then(() => {
          this.loading = false
        })
        .catch(err => parseRequestError(err))
      Promise.all([
        this.$store.dispatch('vehicle/fetchVehicleStatuses'),
        this.$store.dispatch('vehicle/fetchTrackingIdentifiers'),
      ])
        .then(res => {
          this.trackingIdentifiers = cloneNested(res[1])
        })
        .catch(err => parseRequestError(err))
    },
    submitForm() {
      this.$refs.editVehicleValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.loading = true
          const vehicleData = cloneNested(this.vehicle)

          const checklistItems = []
          vehicleData.checklistItems.forEach((item, index) => {
            checklistItems.push({ checklistDictionary: item.checklistDictionary.id, vehicle: vehicleData.id, sortOrder: index + 1 })
          })
          vehicleData.checklistItems = checklistItems

          const payload = {
            id: vehicleData.id,
            data: vehicleData,
          }
          this.$store.dispatch('vehicle/updateVehicle', payload)
            .then(() => {
              window.toast.notify.success(this.$t('conf.vehicle.notify.edit.success_title'))
            })
            .catch(err => {
              parseRequestError(err)
              this.initVehicleData()
            })
            .finally(() => {
              this.loading = false
            })

          return true
        })
    },
    removeChecklistItem(index) {
      const vehicle = cloneNested(this.vehicle)
      vehicle.checklistItems.splice(index, 1)
      this.vehicle = vehicle
    },
    addChecklistItem(item) {
      const checklistItems = cloneNested(this.checklistItems)
      checklistItems.push({
        checklistDictionary: item,
        sortOrder: checklistItems.length + 1,
      })
      this.checklistItems = checklistItems
    },
  },
}
</script>
