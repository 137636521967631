<template>
  <div class="checklist-item-classification-selector">

    <div>
      <v-select
        id="select-checklist-item"
        v-model="queryString"
        :options="suggestions"
        :get-option-label="getOptionLabel"
        :placeholder="$t('conf.vehicle.checklist.search-in-dictionary')"
        @search="fetchResults"
        @option:selected="onSelected"
      >
        <span
          slot="selected-option"
          slot-scope="option"
        >
          {{ option.value }}
        </span>
        <template #option="suggestion">
          {{ suggestion.value }}
        </template>

        <template
          #no-options="{search}"
          class="text-center"
        >
          <span v-if="loading">
            {{ $t('shared.searching') }}
          </span>
          <span v-else-if="search.length > 2">
            {{ $t('shared.no-results') }}
          </span>
          <span v-else>
            {{ $t('shared.write-something') }}
          </span>
        </template>
      </v-select>

    </div>

  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      queryString: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 400,
      shouldRenderSuggestions: (size, loading) => !loading && this.queryString.length > 2,
      suggestions: [],
      loading: false,
    }
  },
  methods: {
    fetchResults(queryString) {
      if (queryString.length < 3) return
      this.loading = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$store.dispatch('settings/searchChecklistItems', queryString)
          .then(res => {
            this.suggestions = []
            this.selected = null

            this.suggestions = res.data.items
          })
          .finally(() => {
            this.loading = false
          })
      }, this.debounceMilliseconds)
    },
    onSelected(suggestion) {
      this.$emit('item:selected', suggestion)
      this.queryString = ''
    },
    getOptionLabel(suggestion) {
      return suggestion.value
    },
  },
}
</script>
